import * as React from 'react'
// import * as css from './404.module.css'
import DefaultLayout from 'layouts/Default'

const NotFoundPg = () => (
  <DefaultLayout className="container side-padding section-padding">
    <h1 className="h1 text-center">Page not found...</h1>
  </DefaultLayout>
)

export default NotFoundPg
